<template>
  <div id="div-vitalSignBodyMass">
    <profile-view-as v-if="patientView && patientView.idCard !== patientInfo.idCard" />
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="flat-primary"
      class="p-0"
      @click="$router.push({ name: 'member-vital-sign-body-mass' }).catch(() => {})"
    >
      <feather-icon
        icon="ChevronLeftIcon"
      />
      <span class="align-middle">{{ t('title.Back') }}</span>
    </b-button>
    <hr>
    <b-row class="mb-1">
      <b-col>
        <h4 v-if="dataType==='temp'">
          {{ t('title.Temp') }}
          <br>
          <small>
            {{ t('title.Temp History') }}
          </small>
        </h4>
        <h4 v-else-if="dataType==='pulse'">
          {{ t('title.Pulse') }}
          <br>
          <small>
            {{ t('title.Pulse History') }}
          </small>
        </h4>
        <h4 v-else-if="dataType==='bp'">
          {{ t('title.BP') }}
          <br>
          <small>
            {{ t('title.BP History') }}
          </small>
        </h4>
        <h4 v-else-if="dataType==='bmi'">
          {{ t('title.BMI') }}
          <br>
          <small>
            {{ t('title.BMI History') }}
          </small>
        </h4>
      </b-col>
    </b-row>
    <vue-apex-charts
      ref="realtimeChart"
      type="line"
      height="400"
      :options="chartOptions"
      :series="series"
    />

    <!-- v-if="dataType!=='bp'" -->
    <b-table
      responsive="sm"
      :items="dataShow"
      sort-by="dateProvider"
      sort-desc
      sticky-header
      no-border-collapse
    >
      <template #cell(dateProvider)="data">
        <span class="text-nowrap">{{ $helpers.getFullDate(data.value.substring(0, 10), $i18n.locale) }}, {{ data.value.substring(11, 16) }}</span>
      </template>
      <template #cell(status)="data">
        <b-badge
          class="d-block"
          :variant="`${variantStatus(data.value)}`"
        >
          {{ data.value }}
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import ProfileViewAs from './ProfileViewAs.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    VueApexCharts,
    ProfileViewAs,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
      patientInfo: JSON.parse(localStorage.getItem('patientData')),
    }
  },
  data() {
    return {
      patientView: JSON.parse(localStorage.getItem('patientTempData')),
      dataType: this.$route.params.id,
      vitalSignBodyMass: [],
      dataShow: [],
      dataShow2: [],
      temp: [],
      pulse: [],
      bp: [],
      bmi: [],
      series: [{
        name: 'Systolic',
        data: [],
        // data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
      }, {
        name: 'Diastolic',
        data: [],
      }],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.warning],
        },
        colors: [$themeColors.primary, $themeColors.success],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          shared: false,
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }</span></div>`
          },
        },
      },
    }
  },
  created() {
    this.loadVitalSignBodyMass()
  },
  methods: {
    changeRoute() {
      // this.$router.push({ name: 'member-appointment' })
    },
    // eslint-disable-next-line consistent-return
    variantStatus(data) {
      if (data === 'ปกติ') {
        return 'success'
      } if (data !== 'ปกติ') {
        return 'warning'
      }
    },
    loadVitalSignBodyMass() {
      this.$http({
        url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/VitalSignAndBodyMass/${this.patientInfo.hn}`,
        // url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/VitalSignAndBodyMass/0227187',
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        const dataTemp = []
        const dataTemp2 = []
        switch (this.dataType) {
          case 'temp':
            for (let i = 0; i < data.tempInfos.length; i += 1) {
              if (data.tempInfos[i].temp < 36) {
                this.dataShow.push({
                  temp: data.tempInfos[i].temp,
                  status: `${this.$t('medical.lower than normal')}`,
                  dateProvider: data.tempInfos[i].dateProvider,
                })
              } else if (data.tempInfos[i].temp >= 36 && data.tempInfos[i].temp <= 37.4) {
                this.dataShow.push({
                  temp: data.tempInfos[i].temp,
                  status: `${this.$t('medical.normal')}`,
                  dateProvider: data.tempInfos[i].dateProvider,
                })
              } else {
                this.dataShow.push({
                  temp: data.tempInfos[i].temp,
                  status: `${this.$t('medical.higher than normal')}`,
                  dateProvider: data.tempInfos[i].dateProvider,
                })
              }
            }
            for (let i = this.checkMaxData(this.dataShow.length) - 1; i >= 0; i -= 1) {
              this.series[0].data.push(this.dataShow[i].temp)
            }
            break
          case 'pulse':
            for (let i = 0; i < data.pulseInfos.length; i += 1) {
              if (data.pulseInfos[i].temp < 60) {
                this.dataShow.push({
                  pulse: data.pulseInfos[i].pulse,
                  status: `${this.$t('medical.lower than normal')}`,
                  dateProvider: data.pulseInfos[i].dateProvider,
                })
              } else if (data.pulseInfos[i].pulse >= 60 && data.pulseInfos[i].pulse <= 100) {
                this.dataShow.push({
                  pulse: data.pulseInfos[i].pulse,
                  status: `${this.$t('medical.normal')}`,
                  dateProvider: data.pulseInfos[i].dateProvider,
                })
              } else {
                this.dataShow.push({
                  pulse: data.pulseInfos[i].pulse,
                  status: `${this.$t('medical.higher than normal')}`,
                  dateProvider: data.pulseInfos[i].dateProvider,
                })
              }
            }
            // for (let i = 0; i < this.checkMaxData(this.dataShow.length); i += 1) {
            for (let i = this.checkMaxData(this.dataShow.length) - 1; i >= 0; i -= 1) {
              this.series[0].data.push(this.dataShow[i].pulse)
            }
            break
          case 'bp':
            // eslint-disable-next-line no-case-declarations
            for (let i = 0; i < data.systolicInfos.length; i += 1) {
              if ((data.systolicInfos[i].systolic >= 90 && data.systolicInfos[i].systolic <= 129) && (data.diastolicInfos[i].diastolic >= 60 && data.diastolicInfos[i].diastolic <= 84)) {
                dataTemp2.push({
                  bp: `${data.systolicInfos[i].systolic}/${data.diastolicInfos[i].diastolic}`,
                  status: `${this.$t('medical.normal')}`,
                  dateProvider: data.pulseInfos[i].dateProvider,
                })
              } else {
                dataTemp2.push({
                  bp: `${data.systolicInfos[i].systolic}/${data.diastolicInfos[i].diastolic}`,
                  status: `${this.$t('medical.abnormal')}`,
                  dateProvider: data.pulseInfos[i].dateProvider,
                })
              }
            }
            this.dataShow = data.systolicInfos
            this.dataShow2 = data.diastolicInfos
            for (let i = this.checkMaxData(this.dataShow.length) - 1; i >= 0; i -= 1) {
              this.series[0].data.push(this.dataShow[i].systolic)
              dataTemp.push({
                bp: `${data.systolicInfos[i].systolic}/${data.diastolicInfos[i].diastolic}`,
                dateProvider: data.systolicInfos[i].dateProvider,
              })
            }
            for (let i = this.checkMaxData(this.dataShow2.length) - 1; i >= 0; i -= 1) {
              this.series[1].data.push(this.dataShow2[i].diastolic)
            }
            this.dataShow = dataTemp2
            break
          case 'bmi':
            for (let i = 0; i < data.bodyMassInfos.length; i += 1) {
              if (data.bodyMassInfos[i].bmi < 18.5) {
                this.dataShow.push({
                  bmi: data.bodyMassInfos[i].bmi,
                  status: `${this.$t('medical.lower than normal')}`,
                  height: data.bodyMassInfos[i].height,
                  weight: data.bodyMassInfos[i].weight,
                  dateProvider: data.bodyMassInfos[i].dateProvider,
                })
              } else if (data.bodyMassInfos[i].bmi >= 18.5 && data.bodyMassInfos[i].bmi <= 22.9) {
                this.dataShow.push({
                  bmi: data.bodyMassInfos[i].bmi,
                  status: `${this.$t('medical.normal')}`,
                  height: data.bodyMassInfos[i].height,
                  weight: data.bodyMassInfos[i].weight,
                  dateProvider: data.bodyMassInfos[i].dateProvider,
                })
              } else if (data.bodyMassInfos[i].bmi >= 23 && data.bodyMassInfos[i].bmi <= 24.9) {
                this.dataShow.push({
                  bmi: data.bodyMassInfos[i].bmi,
                  status: `${this.$t('medical.overweight')}`,
                  height: data.bodyMassInfos[i].height,
                  weight: data.bodyMassInfos[i].weight,
                  dateProvider: data.bodyMassInfos[i].dateProvider,
                })
              } else if (data.bodyMassInfos[i].bmi >= 25 && data.bodyMassInfos[i].bmi <= 29.9) {
                this.dataShow.push({
                  bmi: data.bodyMassInfos[i].bmi,
                  status: `${this.$t('medical.obesity')}`,
                  height: data.bodyMassInfos[i].height,
                  weight: data.bodyMassInfos[i].weight,
                  dateProvider: data.bodyMassInfos[i].dateProvider,
                })
              } else {
                this.dataShow.push({
                  bmi: data.bodyMassInfos[i].bmi,
                  status: `${this.$t('medical.severe obesity')}`,
                  height: data.bodyMassInfos[i].height,
                  weight: data.bodyMassInfos[i].weight,
                  dateProvider: data.bodyMassInfos[i].dateProvider,
                })
              }
            }
            // eslint-disable-next-line no-nested-ternary
            this.dataShow.sort((a, b) => ((a.dateProvider < b.dateProvider) ? 1 : ((b.dateProvider < a.dateProvider) ? -1 : 0)))
            // for (let i = 0; i < this.checkMaxData(this.dataShow.length); i += 1) {
            for (let i = this.checkMaxData(this.dataShow.length) - 1; i >= 0; i -= 1) {
              this.series[0].data.push(this.dataShow[i].bmi)
            }
            break
          default: this.dataShow = []
        }
        if (this.dataType === 'bp') {
          this.$refs.realtimeChart.appendSeries(this.series[1])
        }
        this.settingChart()
      }).catch(error => {
        console.log('loadVitalSignBodyMass', error)
      })
    },
    checkMaxData(totalData) {
      // eslint-disable-next-line radix
      if (parseInt(totalData) >= 20) {
        return 20
      }
      return totalData
    },
    settingChart() {
      // Update Chart Data
      if (this.dataType === 'bp') {
        this.$refs.realtimeChart.updateSeries(this.series, false, true)
      } else {
        this.$refs.realtimeChart.updateSeries([{ data: this.series[0].data }], false, true)
      }
    },
  },
}
</script>
